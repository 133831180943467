import { ScreenSize } from '@/components/ScreenSizes';
import ShareModal, { ShareModalProps } from '@/components/ShareModal';
import Spinner, { SpinnerLocation } from '@/components/Spinner';
import AuthModal from 'components/AuthModal';
import {
  SI_BASE_URL,
  SI_REDIRECT_ENABLED,
  SI_PROXY_PATH,
  SBLIVE_WEB_BASE_URL,
  SI_FEED_PAGES_ENABLED,
  SBLIVE_PATHS
} from 'constants.js.erb';
import { BackParam } from 'components/Link';
import { GenderSportType } from 'constants/GenderSports';
import { StateType } from 'constants/States';
import { createContainer } from 'containers';
import compact from 'helpers/compact';
import { findFanAppRoute, interceptFanAppUrl } from 'helpers/fanAppRouting';
import useToggleableProps from 'hooks/useToggleableProps';
import { ComponentType, PropsWithChildren, useEffect, useState } from 'react';
import { useUser } from 'stores/userStore';
import { ToastMessage, ToastMessageType } from '@/components/ToastMessage';
import { QueryStringParams, getUrlQueryStringParam, makeUrl } from 'helpers/url';
import SiHsPartnerModal from '@/components/SiHsPartnerModal';
import { localCache } from 'helpers/cache';
import useMinuteMediaSessionCookie from 'hooks/useMinuteMediaSessionCookie';

type AuthViewType = 'login' | 'register' | 'password';

interface ApplicationContainerProps {
  baseUrl: string;
  canonical?: string;
  disableAds?: boolean;
  disableVideoAd?: boolean;
  enableOutbrain?: boolean;
  genderSport?: GenderSportType;
  pageTitle?: string; // this should eventually be required
  pathname: string; // does not include SI_PROXY_PATH
  search: string;
  serverScreenSize: ScreenSize;
  state?: StateType;
  variant?: 'fan-app';
}

const SBLIVE_ROUTES = new RegExp(`^\/(${SBLIVE_PATHS})`);

export type ShareProps = Partial<Omit<ShareModalProps, 'onClose'>>;

export interface ToastMessage {
  id: string;
  type: ToastMessageType;
  message: string;
  body?: string;
}

// TODO put zendesk code somewhere else?
declare global {
  interface Window {
    zE?: (...params: string[]) => void;
  }
}

const SI_MAP: Record<string, string | undefined> = {
  baseball: undefined,
  basketball: 'boys-basketball',
  'boys-volleyball': undefined,
  'cross-country': 'boys-cross-country',
  'girls-basketball': undefined,
  'girls-cross-country': undefined,
  'girls-lacrosse': undefined,
  'girls-soccer': undefined,
  football: undefined,
  golf: undefined,
  lacrosee: 'boys-lacrosse',
  soccer: 'boys-soccer',
  softball: 'softball-all-state',
  tennis: undefined,
  volleyball: 'girls-volleyball',
  'water-polo': 'boys-water-polo',
  wrestling: undefined
};

const SI_PARTNER_AGREEMENT_KEY = 'si_partner_agreement';

export const [ApplicationContainer, useApplicationContainer, withApplicationContainer] = createContainer(
  (props: ApplicationContainerProps) => {
    const { hasEnhancedSharingAccess, isLoggedIn } = useUser();
    const [loadingSpinner, setLoadingSpinner] = useState<SpinnerLocation>();
    const [{ isOpen: shareModalIsOpen, props: shareModalProps }, { handleOpen: handleOpenShareModal, handleClose: handleCloseShareModal }] =
      useToggleableProps<Omit<ShareModalProps, 'onClose'>>();
    const [authModalView, setAuthModalView] = useState<AuthViewType>();
    const [isFanApp, setIsFanApp] = useState(props.variant === 'fan-app');
    const [toastMessages, setToastMessages] = useState<ToastMessage[]>();
    const [baseUrl, setBaseUrl] = useState(props.baseUrl);
    const [path, setPath] = useState(`${props.pathname}${props.search || ''}`);
    const [siHsPartnerModalIsOpen, setSiHsPartnerModalIsOpen] = useState(false);
    const [backParam, setBackParam] = useState<string>();

    // update minute media session cookie
    useMinuteMediaSessionCookie();

    useEffect(() => {
      if (baseUrl === SI_BASE_URL && !localCache.get(SI_PARTNER_AGREEMENT_KEY)) {
        setSiHsPartnerModalIsOpen(true);
      }
    }, [baseUrl]);

    useEffect(() => {
      const newIsFanApp = !!window.ReactNativeWebView || props.variant === 'fan-app';
      if (newIsFanApp === isFanApp) return;

      setIsFanApp(newIsFanApp);
    }, [props.variant]);

    // this should only happen on statically generated pages (i.e. 404 pages)
    useEffect(() => {
      const windowBaseUrl = window.location.origin;
      if (baseUrl !== windowBaseUrl) {
        console.warn(`Base URL mismatch: expected '${baseUrl}', got '${windowBaseUrl}'`);
        setBaseUrl(windowBaseUrl);
      }

      const scopedPath = scopeUrl(path);
      const windowPath = `${window.location.pathname}${window.location.search || ''}`;

      if (scopedPath !== windowPath) {
        console.warn(`Path mismatch: expected '${scopedPath}', got '${windowPath}'`);
        setPath(windowPath.replace(SI_PROXY_PATH, ''));
      }
    }, []);

    useEffect(() => {
      if (isLoggedIn) {
        window.zE?.('webWidget', 'show');
      } else {
        window.zE?.('webWidget', 'hide');
      }
    }, [isLoggedIn]);

    function addDomain(path: string) {
      if (path.startsWith('http')) {
        return path;
      } else {
        return `${baseUrl}${scopeUrl(path) || ''}`;
      }
    }

    useEffect(() => {
      setBackParam(getCurrentQueryStringParam('back') || undefined);
    }, [path]);

    function handleShare(shareProps: ShareProps = {}) {
      const title = shareProps.title || props.pageTitle;
      const sharePath = shareProps.path || path;

      // TODO need to move useScreenSize logic here so this works correctly
      if (navigator?.share && props.serverScreenSize === ScreenSize.SMALL) {
        navigator.share({
          title,
          url: addDomain(sharePath)
        });
      } else {
        handleOpenShareModal({ ...shareProps, path: sharePath, title });
      }
    }

    function handleShareGame(game: { id: number | string; titleText: string; webPath: string }) {
      handleShare({
        path: addDomain(game.webPath),
        title: game.titleText,
        embed: hasEnhancedSharingAccess
          ? `<div data-sbl-game-score-id="${game.id}"></div>
          <script src="https://scorebooklive.com/assets/packs/widget-embed.js" aysnc="true"></script>`
          : undefined
      });
    }

    function handleStartLoading(spinnerLocation = SpinnerLocation.CENTER) {
      setLoadingSpinner(spinnerLocation);
    }

    function handleStopLoading() {
      setLoadingSpinner(undefined);
    }

    function handleOpenLoginModal() {
      setAuthModalView('login');
    }

    function handleOpenRegisterModal() {
      setAuthModalView('register');
    }

    function handleCloseAuthModal() {
      setAuthModalView(undefined);
    }

    function getCurrentQueryStringParam(key: string) {
      return getUrlQueryStringParam(path, key);
    }

    function updateCurrentQueryStringParams(params: QueryStringParams) {
      return makeUrl(path, params);
    }

    function rewriteUrl(url?: string, back?: BackParam) {
      if (!url || /^(data|mailto|#)/.test(url)) return url;

      if (!/^(\/|http|sblive|\?)/.test(url)) {
        throw new Error('Relative paths unsupported, use absolute paths instead');
      }

      if (back && !url.startsWith('/')) {
        throw new Error('Back param only supported for relative paths');
      }

      if (isFanApp) {
        const route = findFanAppRoute(url);

        if (route) {
          return `sblive://${route}`;
        } else if (/^(http|mailto)/.test(url)) {
          return url;
        }

        return;
      }

      const scopedUrl = scopeUrl(url);

      if (back) {
        const backValue = back === BackParam.ADD ? btoa(path) : getCurrentQueryStringParam('back');
        if (backValue) {
          return makeUrl(scopedUrl, { back: backValue });
        }
      }

      return scopedUrl;
    }

    function scopeUrl(url: string) {
      if (!url.startsWith('/')) return url;

      if (baseUrl === SI_BASE_URL) {
        if (SBLIVE_ROUTES.test(url)) {
          return `${SBLIVE_WEB_BASE_URL}${url}`;
        } else if (!url.startsWith(SI_PROXY_PATH)) {
          return `${SI_PROXY_PATH}${url}`;
        }
      }

      if (SI_REDIRECT_ENABLED && SI_BASE_URL && !SBLIVE_ROUTES.test(url)) {
        return `${SI_BASE_URL}${SI_PROXY_PATH}${url}`;
      }

      return url;
    }

    function handleOpenLink(url: string, { newWindow }: { newWindow?: boolean } = {}) {
      const rewrittenUrl = rewriteUrl(url);

      if (!rewrittenUrl || interceptFanAppUrl(rewrittenUrl)) return;

      if (newWindow) {
        window.open(rewrittenUrl);
      } else {
        window.location.href = rewrittenUrl;
      }
    }

    function makePath(
      options: {
        state?: { slug: string } | null | false;
        organization?: { slug: string };
        genderSport?: { slug: string } | null | false;
        path?: string;
      } = {},
      params?: QueryStringParams
    ) {
      const state = options.state === null || options.state === false ? undefined : options.state || props.state;
      const genderSport =
        options.genderSport === null || options.genderSport === false ? undefined : options.genderSport || props.genderSport;

      if (SI_FEED_PAGES_ENABLED && !options.path && (!state || !genderSport)) {
        // only if genderSport exists on SI
        if (!genderSport || (genderSport && genderSport.slug in SI_MAP)) {
          return [`https://www.si.com/high-school`, state?.slug, genderSport ? SI_MAP[genderSport.slug] || genderSport?.slug : undefined]
            .filter(compact)
            .join('/');
        }
      }

      return makeUrl('/' + [state?.slug, options.organization?.slug, genderSport?.slug, options.path].filter(compact).join('/'), params);
    }

    function makeHomePath() {
      return makePath({ genderSport: null, state: null });
    }

    function getBackPath(defaultHref: string) {
      let href;
      try {
        if (!backParam) throw 'Back not found';
        href = atob(backParam);
      } catch (e) {
        href = defaultHref;
      }

      return href;
    }

    function replacePath(newPath: string) {
      if (!newPath.startsWith('/')) throw 'Must be absolute path';

      window.history.replaceState({}, '', scopeUrl(newPath));
      setPath(newPath);
    }

    function handleShowToastMessage({ type, message, body }: { type?: ToastMessageType; message: string; body?: string }) {
      setToastMessages(toastMessages => {
        const id = btoa(`${message}${body}${type}${new Date().getTime()}`);
        if (toastMessages?.some(toastMessage => toastMessage.id === id)) {
          return toastMessages;
        } else {
          return [
            ...(toastMessages || []),
            {
              id,
              type: type || ToastMessageType.SUCCESS,
              message,
              body
            }
          ];
        }
      });
    }

    function handleShowErrorMessage(e: any) {
      console.error(e);

      handleShowToastMessage({
        type: ToastMessageType.ERROR,
        message: 'Something went wrong!',
        body: 'Contact support@scorebooklive.com' // TODO what is this email?
      });
    }

    function handleCloseToastMessage(toastMessage: ToastMessage) {
      setToastMessages(toastMessages => toastMessages?.filter(message => toastMessage !== message));
    }

    function handleCloseSiHsPartnerModal(accepted?: boolean) {
      if (accepted) localCache.set(SI_PARTNER_AGREEMENT_KEY, true);
      setSiHsPartnerModalIsOpen(false);
    }

    return [
      {
        ...props,
        baseUrl,
        isFanApp,
        path
      },
      {
        addDomain,
        handleOpenLink,
        handleOpenLoginModal,
        handleOpenRegisterModal,
        handleShare,
        handleShareGame,
        handleStartLoading,
        handleStopLoading,
        makePath,
        makeHomePath,
        rewriteUrl,
        handleCloseToastMessage,
        handleShowToastMessage,
        handleShowErrorMessage,
        getBackPath,
        replacePath,
        getCurrentQueryStringParam,
        updateCurrentQueryStringParams
      },
      children => (
        <>
          {loadingSpinner && <Spinner location={loadingSpinner} />}
          {shareModalIsOpen && <ShareModal {...shareModalProps} onClose={handleCloseShareModal} />}
          {authModalView && <AuthModal type={authModalView} onClose={handleCloseAuthModal} />}
          {siHsPartnerModalIsOpen && <SiHsPartnerModal onClose={handleCloseSiHsPartnerModal} />}
          {toastMessages?.map((toastMessage, i) => (
            <ToastMessage
              key={toastMessage.id || i}
              {...toastMessage}
              onClose={() => handleCloseToastMessage(toastMessage)}
              style={{
                transform: `translateY(-${(toastMessages.length - i - 1) * 83}px)`
              }}
            />
          ))}
          {children}
        </>
      )
    ];
  }
);

export function withApplicationContainerMounter<ComponentProps>(Component: ComponentType<ComponentProps>) {
  return function ({ application, ...props }: ComponentProps & { application: ApplicationContainerProps }) {
    return withApplicationContainer(Component, application)(props as PropsWithChildren<ComponentProps>);
  };
}
